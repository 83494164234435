import 'jquery';

import 'select2';
import 'select2/dist/css/select2.min.css';

(function ($) {
  $(document).ready(($) => {
    $('.gfield_select').select2({
      minimumResultsForSearch: Infinity,
    });

    let objectField = document.getElementsByClassName('contact-object-field');

    const selectObserver = new MutationObserver(function (mutations) {
      for (let mutation of mutations) {
        if (mutation) {
          matchSelectWithSubmit();
        }
      }
    });

    const dropdownObserver = new MutationObserver(function(mutations) {
      for (let mutation of mutations) {
        if (mutation) {
          let rotateSelectArrow = function () {

            $('.select2-selection__rendered').click(function () {
              $(this).parent().find('.select2-selection__arrow').toggleClass('select-dropdown-open');
            })

            if($('.select2-selection__arrow').hasClass('select-dropdown-open')) {
              $('.select2-selection__arrow').removeClass('select-dropdown-open');
            }

            $(document).click(function(event) {
              if(!$(event.target).closest('.select2-selection__rendered').length) {
                if($('.select2-selection__arrow').hasClass('select-dropdown-open')) {
                  $('.select2-selection__arrow').removeClass('select-dropdown-open');
                }
              }
            });
          }

          let observeNodePresence = function (nodesList) {
            for(let node of nodesList) {
              if (node.matches('span[class*="select2-container select2-container--default select2-container--open"]')) {
                rotateSelectArrow();
              }
            }
          }

          observeNodePresence(mutation.addedNodes);
          observeNodePresence(mutation.removedNodes);
        }
      }
    });

    if (objectField.length > 0) {
      let select = objectField[0].lastChild.lastChild.firstChild.firstChild.firstChild;

      let submitButton = document.getElementById('gform_submit_button_3');

      let matchSelectWithSubmit = function () {
        submitButton.setAttribute('value', select.title);
      }

      matchSelectWithSubmit();

      selectObserver.observe(select, {
        childList: true,
      });

      dropdownObserver.observe(document.body, {
        subtree: false,
        childList: true,
      });
    }
  });
})(jQuery);
